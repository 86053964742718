/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import React from "react";
// Vision UI Dashboard React layouts
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import Profile from "layouts/profile";
// import Radio from "layouts/radio";
// import ChronoTask from "layouts/chronotask";
// import Numerology from "layouts/numerology/Numerology";
// import Vacances from "layouts/vacances/Vacances"
// import Loto from "layouts/loto/Loto.jsx";
//import Meteo from "layouts/meteo";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";


const MeteoLazy = React.lazy(() => import("layouts/meteo"));
const DashboardLazy = React.lazy(() => import("layouts/dashboard"))
const TablesLazy = React.lazy(() => import("layouts/tables"))
const BillingLazy = React.lazy(() => import("layouts/billing"))
const ProfileLazy= React.lazy(()=> import( "layouts/profile"));
const RadioLazy= React.lazy(()=> import( "layouts/radio"));
const ChronoTaskLazy= React.lazy(()=> import( "layouts/chronotask"));
const NumerologyLazy= React.lazy(()=> import( "layouts/numerology/Numerology"));
const VacancesLazy= React.lazy(()=> import( "layouts/vacances/Vacances"));
const LotoLazy = React.lazy(() => import("layouts/loto/Loto.jsx"));
const SignInLazy= React.lazy(()=>import ("layouts/authentication/sign-in"));
const SignUpLazy = React.lazy(() => import("layouts/authentication/sign-up"));
const IssLazy= React.lazy(()=>import("layouts/iss/"))
// Vision UI Dashboard React icons
import { IoRocketSharp, IoHome, IoStatsChart, IoBuild } from "react-icons/io5";
import { SiMedium } from "react-icons/si";
import { GiTimeSynchronization, GiBlackball, GiRadioTower } from "react-icons/gi";
import { GrHomeRounded } from "react-icons/gr";
import { BsFillPersonFill, BsCreditCardFill } from "react-icons/bs";
import {TiWeatherPartlySunny} from "react-icons/ti"
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="25px" color="inherit" />,
    component: <DashboardLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    route: "/tables",
    icon: <IoStatsChart size="25px" color="inherit" />,
    component: <TablesLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <BsCreditCardFill size="25px" color="inherit" />,
    component: <BillingLazy />,
    noCollapse: true,
  },
  { type: "title", title: "Développement React", key: "account-pages" },
  {
    type: "collapse",
    name: "Météo Netatmo",
    key: "meteo",
    route: "/meteo",
    icon: <TiWeatherPartlySunny size="25px" color="inherit" />,
    component: <MeteoLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Localisation Iss",
    key: "iss",
    route: "/iss",
    icon: <TiWeatherPartlySunny size="25px" color="inherit" />,
    component: <IssLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Radio Frequences",
    key: "radio",
    route: "/radio",
    icon: <GiRadioTower size="25px" color="inherit" />,
    component: <RadioLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ChronoTaches",
    key: "chronotask",
    route: "/chronotask",
    icon: <GiTimeSynchronization size="25px" color="inherit" />,
    component: <ChronoTaskLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Numerology",
    key: "numerology",
    route: "/numerology",
    icon: <SiMedium size="25px" color="inherit" />,
    component: <NumerologyLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Vacances",
    key: "vacances",
    route: "/vacances",
    icon: <GrHomeRounded size="25px" color="inherit" />,
    component: <VacancesLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Loto",
    key: "loto",
    route: "/loto",
    icon: <GiBlackball size="25px" color="inherit" />,
    component: <LotoLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="25px" color="inherit" />,
    component: <ProfileLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <BsFillPersonFill size="25px" color="inherit" />,
    component: <SignInLazy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <IoRocketSharp size="25px" color="inherit" />,
    component: <SignUpLazy />,
    noCollapse: true,
  },
];

export default routes;
